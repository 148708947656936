import React, { useEffect, useRef, useState } from "react";
import backgroundImage from "./images/background.jpg";
import avatar from "./images/tx.jpg";
import pay from "./images/pay.jpg";
import wechat from "./images/wx.jpg";

interface Item {
  id: string;
  title: string;
  url: string;
  selected: boolean;
}

const items: Item[] = [
  {
    id: "1",
    title:
      "【服务】毕业设计/课程设计指导 网页开发 Java/Vue.js (根据实际需求内容定价)",
    url: "",
    selected: true,
  },
  {
    id: "2",
    title: "【服务】网页开发 Java/Golang/Vue.js (根据实际需求内容定价)",
    url: "",
    selected: false,
  },
  {
    id: "3",
    title: "【服务】爬虫/RPA Python (根据实际需求内容定价)",
    url: "",
    selected: false,
  },
  {
    id: "4",
    title: "【资源】1T 编程学习资源，涵盖Web开发，数据科学、网络安全等 (¥ 5)",
    url: "",
    selected: false,
  },
];

const websiteUrl = "https://jonssonyan.com";
const socialMediaUrls = {
  youtube: "https://www.youtube.com/@jonssonyan",
  bilibili: "https://space.bilibili.com/374864141",
};

function App() {
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [showToTopButton, setShowToTopButton] = useState(false);
  const scrollY = useRef<number>(0);

  useEffect(() => {
    for (let item of items) {
      if (item.selected) {
        setSelectedItem(item);
        break;
      }
    }

    const handleScroll = () => {
      scrollY.current = window.scrollY;
      setShowToTopButton(scrollY.current > window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const clickClass = (item: Item) => {
    setSelectedItem(item);
    items.forEach((i) => {
      i.selected = i.id === item.id;
    });
  };

  const renderSelectedItem = () => {
    if (!selectedItem || !selectedItem.url) return null;

    return (
      <img
        src={selectedItem.url}
        alt="Item"
        className="object-cover rounded-lg shadow-md w-full md:max-h-800"
      />
    );
  };

  const renderSocialLinks = () => (
    <div className="text-gray-500 max-w-md">
      <div>
        Web开发，网络编程，开源软件作者，开发的软件全网累计装机量超100万
      </div>
      <div>
        社交媒体：
        <a href={socialMediaUrls.youtube} className="underline">
          YouTube
        </a>
        {" & "}
        <a href={socialMediaUrls.bilibili} className="underline">
          哔哩哔哩
        </a>
      </div>
    </div>
  );

  const renderItems = () => (
    <div className="grid gap-4">
      {items.map((item) => (
        <div
          key={item.id}
          className="flex items-center bg-white p-6 rounded-lg shadow-md hover:shadow-xl cursor-pointer transition-shadow duration-300 ease-in-out"
          onClick={() => clickClass(item)}
        >
          <div
            className="flex items-center justify-between text-sm font-semibold text-gray-800"
            style={{ flex: "1" }}
          >
            {item.title}
          </div>
          <div
            className={`ml-4 w-4 h-4 rounded-full border-2 border-gray-500 cursor-pointer ${item.selected ? "bg-green-500" : "bg-white"}`}
          />
        </div>
      ))}
    </div>
  );

  const renderDetail = () => {
    if (selectedItem) {
      if (selectedItem.id === "1") {
        return (
          <>
            <h2 className="text-3xl mb-4 font-bold text-gray-800">
              毕业设计/课程设计指导
            </h2>
            <p className="text-lg text-gray-700">
              技术栈：Java、Vue.js、Java Web、Spring、Spring
              Boot、Mybatis/Mybatis Plus、Spring Cloud、Spring Security、Shiro
              等
            </p>
            <p className="text-lg text-gray-700">
              根据实际需求内容定价，{contactMe()}
            </p>
          </>
        );
      } else if (selectedItem.id === "2") {
        return (
          <>
            <h2 className="text-3xl mb-4 font-bold text-gray-800">网页开发</h2>
            <p className="text-lg text-gray-700">
              技术栈：Java、Vue.js、Golang、Spring、Mybatis、Gin、GORM 等
            </p>
            <p className="text-lg text-gray-700">
              根据实际需求内容定价，{contactMe()}
            </p>
          </>
        );
      } else if (selectedItem.id === "3") {
        return (
          <>
            <h2 className="text-3xl mb-4 font-bold text-gray-800">爬虫/RPA</h2>
            <p className="text-lg text-gray-700">
              技术栈：Python、Playwright 等
            </p>
            <p className="text-lg text-gray-700">
              根据实际需求内容定价， {contactMe()}
            </p>
          </>
        );
      }
    }
  };

  const contactMe = () => {
    return (
      <>
        请联系我&nbsp;
        <a
          href="https://t.me/jonssonyan"
          className="underline underline-offset-4"
        >
          Telegram
        </a>
        &nbsp;&&nbsp;
        <a href={wechat} className="underline underline-offset-4">
          微信
        </a>
      </>
    );
  };

  return (
    <div
      className="min-h-screen flex flex-col justify-center items-center bg-custom bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundAttachment: "fixed",
      }}
    >
      <div className="max-w-screen-2xl mx-auto bg-white bg-opacity-90 p-8 rounded-lg shadow-lg overflow-hidden">
        <h1 className="text-4xl mb-6 font-extrabold text-center text-gray-900 border-b-4 border-gray-700 pb-2">
          JONSSONYAN - 付费的资源和服务
        </h1>

        <div className="flex flex-col md:flex-row gap-10 p-6 flex-grow">
          <div className="w-full md:w-1/2 flex justify-center items-center">
            {renderSelectedItem()}
          </div>

          <div className="w-full md:w-1/2 flex flex-col items-start space-y-6">
            <div className="flex flex-wrap justify-center items-center w-full p-4 bg-white rounded-lg shadow">
              <a href={websiteUrl}>
                <img
                  src={avatar}
                  alt="Avatar"
                  className="w-16 h-16 rounded-full"
                />
              </a>
              <div className="ml-4">
                <div className="text-xl font-semibold">
                  <a href={websiteUrl}>jonssonyan</a>
                </div>
                {renderSocialLinks()}
              </div>
            </div>

            <div className="text-2xl font-bold text-gray-800">服务列表</div>
            {renderItems()}

            <div className="text-2xl font-bold text-gray-800">支付方式</div>
            <p className="text-base font-bold text-gray-800 text-red-500">
              【资源】类，请扫码支付（请务必备注邮箱和服务名称）最迟24小时内发送至备注邮箱
            </p>
            <div className="flex justify-center items-center h-full">
              <img
                src={pay}
                alt="Pay"
                className="object-contain rounded-lg shadow-md md:w-3/4"
              />
            </div>
            <p className="text-base text-gray-700">
              如未收到或有任何疑问，{contactMe()}
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col items-center mt-10">
          {renderDetail()}
        </div>
      </div>
      {showToTopButton && (
        <button
          className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full shadow-md"
          onClick={handleToTop}
        >
          返回顶部
        </button>
      )}
    </div>
  );
}

export default App;
